@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  cursor: url("../public/icons/Multiplayer.svg") 19 19,auto;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap');

@font-face {
    font-family: Eskell-Display;
    src: url("fonts/Eksell_Display_Large.otf") format("opentype");
  }
  @font-face {
    font-family: Helvetica;
    src: url("fonts/Helvetica.ttf") format("opentype");
  }
  @font-face {
    font-family: Monument-Extended;
    src: url("fonts/MonumentExtended-Regular.otf") format("opentype");
  }
@font-face {
  font-family: GT-America-Mono-Trial;
  src: url("fonts/GT-America-Mono-Regular.ttf");
}

  @font-face {
    font-family: GT-America-Trial;
    src: url("fonts/GT America Regular.otf") format("opentype");
  }

  @font-face {
    font-family: mangal;
    src: url("fonts/MANGAL.ttf") format("ttf");
  }

